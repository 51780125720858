import {useEffect, useState} from 'react'
import {useIntl} from 'react-intl'
import {PageTitle} from '../../../_metronic/layout/core'
import { getOrderList, localStatus, getSearcherCompany, rushButton, updateSearchCompany, getPricingDetails } from '../../services/order.service'
import Loading from '../../components/loading.component'
import { OrderModel } from '../../models/orders/order.model'
import { useAuth } from '../../contexts/auth.context'
import { OrderTable } from '../../components/order-table.component'
import { GridColDef, GridComparatorFn } from '@mui/x-data-grid'
import { Link, useLocation } from 'react-router-dom'
import { TopFilterItem } from '../../components/top-filter/top-filter-item.component'
import { DefaultTableModel, ITableModel } from '../../models/orders/table.model'
import { UTCNoTime,UTCFormatted, base64Encode, xorEncrypt} from '../../utils/global.utils';
import { filterOrders as filterOrdersService } from '../../services/order.service'
import ThreeDotMenu from '../../components/menu-dots/menu-dots'
import { KTSVG } from '../../../_metronic/helpers'
import Popup from '../../components/message-form/pop-up-form'
import { OrderForm } from '../../components/message-form/order-form.component'
import { Tooltip } from '@mui/material'
import parse, { Element } from 'html-react-parser';

const rushPriority = ['NoRush', 'Rush', 'RushPlus'];
let pagination = true;

export const ORDER_STATUS = {
  'received': {
    title: 'Pending',
    showWizard: true,
    icon: '/media/ATR/icons/icons8-pending-50.svg',
    size: 0,
    data: [] as OrderModel[]
  },
  'progress': {
    title: 'In Progress',
    showWizard: true,
    icon: '/media/ATR/icons/icons8-in-progress-50.svg',
    size: 0,
    data: [] as OrderModel[]
  },
  'done': {
    title: 'Completed',
    showWizard: false,
    icon: '/media/ATR/icons/icons8-checkmark-50.svg',
    size: 0,
    data: [] as OrderModel[]
  },
  'ignore': {
    title: 'Cancelled',
    showWizard: false,
    icon: '/media/ATR/icons/icons8-cancel-64.svg',
    size: 0,
    data: [] as OrderModel[]
  },
  'all': {
    title: 'All',
    showWizard: false,
    icon: '/media/ATR/icons/job.svg',
    size: 0,
    data: [] as OrderModel[]
  }
}

type StateProps = {
  tab: string; 
}

const DashboardPage = () => {
  const {auth} = useAuth();
  const location = useLocation();
  const state = location.state as StateProps;
  const tab = state && state.tab;
  const [showSplashScreen, setShowSplashScreen] = useState(true);
  const [status, setStatus] = useState<string>('received');
  const [orderStatus, setOrderStatus] = useState(ORDER_STATUS);
  const [firstload, setFirstLoad] = useState(true);
  const [tableState, setTableState] = useState<ITableModel>(DefaultTableModel);
  const [results, setResults] = useState<any[]>([]);
  const [showPopup, setShowPopup] = useState(false);
  const [cardclicked, setCardclicked] = useState(''||tab)
  const [modalOpen, setModalOpen] = useState(false);
  const [filtered, setFiltered] = useState(false);

  const parseHTML = (input: string) =>
    parse(input, {
        replace: domNode => {
        if (domNode instanceof Element) {
            return <></>;
        }
        }
    });

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  useEffect(() => {
    getSearcherCompany().then(results=>{
      if(results && results.success && results.success.msg && results.success.msg.length> 0){
        setResults(results.success.msg)
      }
    }).finally(() => {
      console.log(results);
    })
    
  }, [])
  

  const updatePageState = (prop:string, value:any) => {
    setTableState(old => ({...old, [prop]: value}))
  }

  const filterOrders = (event: any, status: string) => {
    setFiltered(false);
    setStatus(status)
    setCardclicked(status)
  }

  const renderPropertyRow = (params:any) => {
    const {orderID, tab} = params.row;
    const encodeOid = base64Encode(xorEncrypt(orderID.toString()));
    return (
      <Link to={`/orders/details/${encodeOid}`} state={{tab: tab}} className='text-decoration-underline'>{params.row.property}</Link>
    )
  }

  const RenderStatusRow = (params:any) => {
    const [rowParams, setRowParams]: any[] = useState([]);
    useEffect(() => {
      setRowParams(params.params);
    }, [params, firstload])
    return ( 
      <Tooltip title={rowParams && rowParams.row && rowParams.row.status ? rowParams.row.status : '' } arrow>
        <span className='table-cell-truncate'>
          { rowParams && rowParams.row && rowParams.row.status === 'Pending' ?
            <KTSVG path={orderStatus['received'].icon} className={`svg-icon-white svg-icon-29 ms-n1`} /> 
          : rowParams && rowParams.row && rowParams.row.status === 'In Progress' ? 
            <KTSVG path={orderStatus['progress'].icon} className={`svg-icon-white svg-icon-29 ms-n1`} /> 
          : rowParams && rowParams.row && rowParams.row.status === 'Completed' ?
            <KTSVG path={orderStatus['done'].icon} className={`svg-icon-white svg-icon-29 ms-n1`} /> 
          : rowParams && rowParams.row && rowParams.row.status === 'Cancelled' ?
            <KTSVG path={orderStatus['ignore'].icon} className={`svg-icon-white svg-icon-29 ms-n1`} /> 
          : ''
          }
        </span>
      </Tooltip>
    )
  }

  const renderNotificationsRow = (params:any) => {
    const {orderID, tab} = params.row;
    const encodeOid = base64Encode(xorEncrypt(params.row.orderID.toString()));
    return (
      <div>
        {params.row.notifications && params.row.notifications === true ?
          <Link to={`/orders/details/${encodeOid}`} state={{tab: tab}} className="text-decoration-underline">View</Link>
        : ''
        }
      </div>
    )
  }
  const RenderAssignmentRow = (params:any) => {
    const [rowParams, setRowParams]: any[] = useState([]);
    const [assignedToValue, setAssignedToValue] = useState('0'); 
    const [firstload, setFirstLoad] = useState(false);
    const [buttonClass, setButtonClass] = useState('btn btn-secondary disabled');
    useEffect(() => {
      if(params && params.params){
        setRowParams(params.params);
        setFirstLoad(true);
      }
    },[params])
    useEffect(() => {
      if(results && rowParams && rowParams.row && rowParams.row.assignedTo && firstload){
        var searchCode = results.find(r => r.searcher_SCOMP_ID === rowParams.row.assignedTo.id);
        if(searchCode){
          setAssignedToValue(searchCode["searcher_SCOMP_Code"])
          setButtonClass('btn btn-primary');
        }
      }
    }, [results, firstload])
    useEffect(() => {
      if(assignedToValue){
        setTableState(old => ({
          ...old,
          columns:columns,
        }))
      }
    },[assignedToValue]
    )
  
    const handleAssignedToChange = (event:any) => {
      const selectedValue = event.target.value;
      setAssignedToValue(event.target.value);
      if (selectedValue === '0') {
        setButtonClass('btn btn-secondary disabled'); 
      } else {
        setButtonClass('btn btn-primary'); 
      }
    }

    const submitAssign = () => {
    if(assignedToValue !== "0")
      {
        var searchCompany = results.find(r => r.searcher_SCOMP_Code === assignedToValue)["searcher_SCOMP_ID"];
        console.log(searchCompany);
        var orderId = parseInt(`${rowParams.row.orderID}`);
        console.log(orderId);
        updateSearchCompany(orderId, searchCompany).then((res) => {
          console.log(res);
          setShowPopup(true);  
      });
    }
  }
  const handleClose = () => setShowPopup(false);
    return (
      <div>
        {rowParams && rowParams.row && rowParams.row.tab === 'received'? (
          <div>
            <select
              className='form-select form-select-sm form-select-solid'
              tabIndex={params && params.hasFocus ? 0 : -1}
              value={assignedToValue}
              onChange={handleAssignedToChange}
            >
              <option key="0" value="0">Choose Vendor</option>
              {results && results.map(item => (
                item.searcher_SCOMP_Company && item.searcher_SCOMP_Company.length > 1 ? (
                  <option key={item.searcher_SCOMP_ID} value={item.searcher_SCOMP_Code}>
                    {item.searcher_SCOMP_Company}
                  </option>
                ) : ''
              ))}
            </select>
            <button className={buttonClass} onClick={submitAssign}>Submit</button>
          </div>
        ) : rowParams && rowParams.row && rowParams.row.assignedTo ? rowParams.row.assignedTo.name : "N/A"}
        {showPopup && (
          <Popup
            message="Update has been made successfully"
            onClose={handleClose}
          />
        )}
      </div>
    );
  };
  

  const RenderRushActionRow = ({params,tableStateType}:any) => {
    const [rowParams, setRowParams]: any[] = useState([])
    const [isButtonClicked, setIsButtonClicked] = useState(false)
    const [buttonType,setButtonType]=useState("")
    const [apiDatatoSend, setApiDatatoSend] = useState({
      email: "",
      fileNumber: "",
      transactionId: "",

    })
    const [isOpen, setIsOpen] = useState(false)
    const userEmail = auth?.email
    useEffect(() => {
      if (params && params) {
        setRowParams(params)
      }
    }, [params])

    
    const menuOptions : any[] = [
      {
        label: tableStateType === 'done' ? 'Rundown' : 'Rush',
        icon: (
          <KTSVG
            path="/media/ATR/icons/Icon awesome-shipping-fast.svg"
            className="svg-icon-1 mr-icon"
          />
        ),
        onclick: () => {
          // Existing Rundown functionality
          if (rowParams && rowParams.row && userEmail && !isButtonClicked) {
            //setIsButtonClicked(true); abale to click on 2nd time
            if (tableStateType !== 'done') {
              //var searchCompany = results.find(r => r.searcher_SCOMP_Code === assignedToValue)["searcher_SCOMP_ID"];
              console.log(rowParams.row);
              rushButton(userEmail, rowParams.row.fileNumber, rowParams.row.assignedTo.id, rowParams.row.transactionId);
              setShowPopup(true);
            }
            else if (tableStateType === 'done') {
              setIsOpen(true)
              setApiDatatoSend({
                email: userEmail,
                fileNumber: rowParams.row.fileNumber,
                transactionId: rowParams.row.transactionId,

              })
            }

          }

        },
        isButtonClicked: setIsButtonClicked,
        disable: isButtonClicked
      },
    ]

    if(tableStateType === 'done') {
      menuOptions.push({
        label: 'Cover',
        onclick: () => {
          if (rowParams && rowParams.row && userEmail && !isButtonClicked) {
            setIsOpen(true);
            setApiDatatoSend({
              email: userEmail,
              fileNumber: rowParams.row.fileNumber,
              transactionId: rowParams.row.transactionId,
            });
          }
        },
        isButtonClicked: setIsButtonClicked,
        disable: isButtonClicked
      })
    }

    return (
      <div>
        <ThreeDotMenu options={menuOptions} setModalOpen={setModalOpen} setButtonType={setButtonType}/>
       <OrderForm apiDatatoSend={apiDatatoSend} open={isOpen} setOpen={setIsOpen} buttonType={buttonType}/>
      </div>
    );
  };
  

  
  useEffect(() => {
    setTableState((old) => ({
      ...old,
      columns: columns,
    }))
  }, [results, cardclicked])

  useEffect(() => {
    // We have to show toolbar only for dashboard page
    document.getElementById('kt_layout_toolbar')?.classList.remove('d-none')
    return () => {
      document.getElementById('kt_layout_toolbar')?.classList.add('d-none')
    }
  }, [])

  useEffect(() => { 
    if(auth && auth.api_token) {
        fetchOrderList(status);
    }
    setTableState(old => (
      {
        ...old, 
        columns: columns, 
        pagination: pagination,
        hiddenColumns: hiddenColumns,
        settings: {...old.settings, 
          breadcrumb: 'Orders',
          showCount: true,
          showButton: process.env.REACT_APP_ENV == "fuckingshit",
          buttonText: 'New Order',
          buttonLink: '/orders/new',
          hideComponents: false,
          updatePageState: updatePageState}
      }
    ))
    
  },[]);

  useEffect(() => {
    if(status) {
        let sortedOrders = orderStatus[status].data.sort((a:OrderModel, b:OrderModel) => {
          if(a.rushPriority && b.rushPriority) return b.rushPriority - a.rushPriority
        })
        var tmpRows:any = [];
        if(sortedOrders.length != 0){
          tmpRows = sortedOrders.map((row:OrderModel, index:number) => {
            console.log(row.transaction_searchCompanyName);
            const id = index + 1;
            return  {
              id: id,
              transactionId: row.transaction_id? row.transaction_id: 0,
              orderID: row.tgOrder_tgOrderId? row.tgOrder_tgOrderId: 0,
              fileNumber: row.transaction_agencyOrderId? row.transaction_agencyOrderId : 0,
              property: row.transaction_address? row.transaction_address: '',
              county: row.transaction_county? row.transaction_county: '',
              state: row.transaction_state? row.transaction_state: '',
              city: row.transaction_city? row.transaction_city: '',
              status: status !== 'all' ? orderStatus[status].title : orderStatus[row.finalStatus ? row.finalStatus : ''].title,
              tab: status,
              product: `${row.transaction_searchType},<br/> ${row.transactionType_type}`,
              orderDate:  row.transaction_transactionDateTime ? UTCNoTime (row.transaction_transactionDateTime): null,
              deliveredDate: row.transaction_completeMovementDate ?  UTCNoTime(row.transaction_completeMovementDate) : null,
              closingDate: row.transaction_currentDueDate ?  UTCNoTime (row.transaction_currentDueDate) : null,
              assignedTo: {name: row.transaction_searchCompanyName, id: row.transaction_searchCompanyId},
              notifications: row.transaction_comments,
              rush: row.rushPriority,
              rushAction: row.rushAction ? row.rushAction : ''
            }
          });
        }
        setTableState(old => (
          {
            ...old,
            rows: tmpRows, 
            rowCount: results && results.length > 0 ? results[0].count : 0,
            settings: {
              ...old.settings,
              title: `${orderStatus[status].title} Orders`
            }
          }
        ))
    }
  },[orderStatus, firstload]);


  useEffect(() => {
    if(orderStatus.all.size > 0) {
      setFirstLoad(false)
    }
  },[orderStatus.all.size])

  useEffect(() => {
    if(!filtered) {
      fetchOrderList(status);
    }
  }, [status])

  // This is causing the page to crash when you change from an empty result to a normal result and viceversa.
  // Rendering for rush button and assign row is causing this to crash when the column visibility changes
  useEffect(() => {
    if (status && !filtered) {
      var assignedTo = true;
      var rushAction = true;
      //
      // if(auth?.email !== 'sdoglu@actiontitleresearch.com'){
      //   assignedTo = false;
      //   rushAction = false;
      // }
      // else{
      rushAction = status === 'progress' || status === 'received' || status === 'done'
      //}
      setTableState((old) => ({
        ...old,
        page: 0,
        status: status,
        filterText: null,
        hiddenColumns: {
          ...old.hiddenColumns,
          deliveredDate: (status === 'done' || status === 'all'),
          closingDate: (status === 'all') || (status === 'progress') || (status === 'received'),
          assignedTo: assignedTo,
          rushAction: rushAction,
          status: status === "all"
        }
      }));
      
    }
  }, [status]);

  
  useEffect(() => {
    if(!firstload) {
      let tmpStatus = undefined;
      if(location.state) { 
        const {tab} = location.state as StateProps;
        tmpStatus = tab;
      } else {
        tmpStatus = 'received';
      }

      setStatus(tmpStatus);
    }
  },[firstload])

  useEffect(() => {
    if(status) {
      if(orderStatus[status].size > 0) {
        if(tableState.rows.length > 0)
          setTableState(old => ({...old, loading: false}))
      } else {
        setTableState(old => ({...old, loading: false}))
      }
    }

    if(tableState.rowCount === 0) {
      setTableState(old => ({...old, loading: false}))
    }
      
  },[tableState.rows])

  useEffect(() => {
    if(tableState.filterText && tableState.filterText.trim().length > 0) {
      if(tableState.filterText === '_reset') {
        fetchOrderList(status);
      } else {
        setFiltered(true);
        setStatus('all');
        filterData('all');
      }
    }
  }, [tableState.filterText])

const updateOrderStatus = (tmpStatus:string|undefined, response:any, tmpOrderStatus:any, isFiltered:boolean) => {
  if(tmpStatus && response.results) {
    const results:OrderModel[] = response.results;

      tmpOrderStatus[tmpStatus].data = results.map(order => {
        tmpStatus = !status ? localStatus(order.transaction_currentProcessingQueue, order.transaction_status).status : status;
        return {...order, 
                  finalStatus: localStatus(order.transaction_currentProcessingQueue, order.transaction_status).status,
                  rushPriority: order.transaction_rush ? (rushPriority.indexOf(order.transaction_rush)+1): 0,
              }
      });
      if(!isFiltered) {
        tmpOrderStatus[tmpStatus].size = response.count;

      }
  }
  return tmpOrderStatus;
}

const fetchOrderList = (tmpStatus:string|undefined) => {
  let tmpOrderStatus = {...orderStatus};
  setTableState(old => ({...old, loading:true}))

  getOrderList(tmpStatus).then(response => {
    if(response) {
      if(tmpStatus && response.results) {
        tmpOrderStatus = updateOrderStatus(tmpStatus, response, tmpOrderStatus, false);
      }
      
      if(response.rows) {
       const rows = response.rows;
       Object.keys(rows).map(key => tmpOrderStatus[key].size = rows[key]);
      }
    }
  })
  .finally(() => { 
    setOrderStatus(tmpOrderStatus);
    console.log(orderStatus);
    setShowSplashScreen(false); 
  })
}

const filterData = (tmpStatus:string) => {
  let tmpOrderStatus = {...orderStatus};
  const filterStatus = '';
  setTableState(old => ({...old, loading:true}))

  if(tableState && tableState.filterText){
    filterOrdersService(tableState.filterText, filterStatus).then(data => {
      if(data) {
        tmpOrderStatus = updateOrderStatus(tmpStatus, data, tmpOrderStatus, true);
      }
    }).catch()
    .finally(() => {
      setOrderStatus(tmpOrderStatus);
      setTableState(old => ({
        ...old,
        hiddenColumns: {
          ...old.hiddenColumns,
          deliveredDate: true,
          closingDate: true,
          status: true
        },
        loading:false
      }))
      setShowSplashScreen(false); 
    })
  }
 
}

const dayInMonthComparator: GridComparatorFn<Date> = (v1, v2) =>
  {
    return new Date(v1).getTime() - new Date(v2).getTime()
  };

  const columns: GridColDef[] = [
    { field: 'id', headerName: '#', flex: 0.1, minWidth: 28 , filterable:false , sortable:false, hide:false, hideable:false, disableColumnMenu:true},
    { field: 'status', headerName: '', flex: 0.1, minWidth: 29, renderCell: (params) => <RenderStatusRow params={params}/> },
    { field: 'fileNumber', headerName: 'File Number', flex: 0.75, minWidth: 100, maxWidth: 120 },
    { field: 'property', headerName: 'Property', renderCell: renderPropertyRow, maxWidth: 200},
    { field: 'county', headerName: 'County', flex: 0.5, minWidth: 90},
    { field: 'state', headerName: 'State', flex: 0.5, minWidth: 90 },
    { field: 'city', headerName: 'Town/City', flex: 0.5, minWidth: 90 },
    { field: 'product', headerName: 'Product', flex: 0.30, minWidth: 120, renderCell: (params) => parseHTML(params.row.product) },
    { field: 'orderDate', headerName: 'Order Date', align: "center", flex: 0.25, minWidth: 90, sortComparator: dayInMonthComparator },
    { field: 'deliveredDate', headerName: 'Delivered Date', align: "center", /*renderCell: renderDeliveredDateRow,*/ flex: 0.15, minWidth: 116, sortComparator: dayInMonthComparator },
    { field: 'closingDate', headerName: 'Expected Date', align: "center", /*renderCell: renderClosingDateRow,*/ flex: 0.15, minWidth: 110, sortComparator: dayInMonthComparator },
    { field: 'assignedTo', headerName: 'Assigned to', flex: 0.15, minWidth: 150, renderCell: (params) => <RenderAssignmentRow params={params} /> },
    { field: 'notifications', headerName: 'Notes', flex: 1, maxWidth: 70, renderCell: renderNotificationsRow },
    { field: 'rush', headerName: 'Rush' },
    { field: 'rushAction', headerName: 'Action', flex: 1, maxWidth: 60, renderCell: (params) => (<RenderRushActionRow params={params} tableStateType={cardclicked} />), },
    { field: 'transactionId', headerName: 'traID' }
  ];

  const hiddenColumns = {
    rush: false,
    rushAction: false,
    closingDate: false,
    deliveredDate: false,
    assignedTo: false,
    transactionId: false,
    status:false

  };

  return (
    <>
    {showPopup && (
          <Popup
            message="Update has been made successfully."
            onClose={handleClosePopup}
          />
        )}
      <PageTitle breadcrumbs={[]} description='#XRS-45670'>
        Dashboard
      </PageTitle>
      {
        showSplashScreen ? <Loading/> : 
        (
          <>
          <div className="top-cards mb-6">
            <div className='top-filter'>
            {
              Object.keys(orderStatus).map((key, index) => {
                  const item = orderStatus[key];
                  const cardStatus = key;
                  const size = item.size;
                  return (
                    <div onClick={(e) => filterOrders(e, cardStatus)} key={index}>
                      <TopFilterItem
                        className='card-xl-stretch'
                        svgIcon={item.icon}
                        color={`${cardStatus === status ? 'success' : 'white'}`}
                        iconColor='white'
                        title={size}
                        titleColor='primary'
                        description={`${item.title} Orders`}
                        descriptionClasses='text-decoration-underline'
                        descriptionColor='black'
                        status= {cardStatus}
                      />
                    </div>
                  )
              })
            }
            </div>
          </div>
          <div className='g-5 gx-xxl-8 border-gray rounded dashboard-table'>
            <OrderTable 
              tableState={tableState} 
              className='bg-white new-order-card'  />
          </div>
        </>
        )
      }
    </>
  )
}

const DashboardWrapper = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
